
import Exchange from "hpay/contracts/Exchange.json";
import Fund from "hpay/contracts/Fund.json";
import HedgeCoin from "hpay/contracts/HedgeCoin.json";
import Staking from "hpay/contracts/HedgeCoinStaking.json";
import Transfer from "hpay/contracts/Transfer.json";
import RewardStash from "hpay/contracts/RewardStash.json";
import { useCallback, useEffect, useState } from "react";
import { ContractFactoryHof } from "./contract.factory";
import { getProvider } from '../../web3/web3.js';
import { formatUnits } from 'ethers/lib/utils';

export const Tokens = {
    BUSD: {
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        decimals: 18,
        chainId: 56,
        core: true,
        symbol: 'BUSD',
        image: "/busd.svg"
    },
    BNB: {
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18,
        chainId: 56,
        core: true,
        symbol: 'BNB',
        image: "/bnb.svg"
    },
    HPAY: {
        address: HedgeCoin.address,
        decimals: 18,
        chainId: 56,
        proxy: true,
        symbol: 'HPAY',
        image: "/hpay.svg"
    },
};

export const ContractAddresses = {
    BUSD: Tokens.BUSD.address,
    BNB: Tokens.BNB.address,
    HPAY: HedgeCoin.address,
    STASH: RewardStash.address,
    FUND: Fund.address,
    STAKING: Staking.address,
    EXCHANGE: Exchange.address,
    TRANSFER: Transfer.address
};

export const ContractFactory = ContractFactoryHof(window.web3);

const CONTRACTS = {
    _BUSDContract_instance: null,
    _BNBContract_instance: null,
    _BnbBusdContract_instance: null,
    _HegeToken_instance: null
};

export const getContract = async (contract, address, abi, networkId = 56) => {
    const contractKey = `${contract}:${networkId}`;
    if (CONTRACTS[contractKey]) {
        return CONTRACTS[contractKey];
    }

    const provider = getProvider(networkId)
    CONTRACTS[contractKey] = ContractFactoryHof(provider).create(
        abi,
        address
    );
    return CONTRACTS[contractKey];
};


export const useContractWithProvider = (address, abi, provider) => {
    const [contract, setContract] = useState();
    const getContract = useCallback(async () => {
        try {
            if (!address || !provider) {
                return;
            }

            const factory = ContractFactoryHof(provider);
            const _contract = await factory.create(abi, address);
            if (!_contract) {
                return;
            }
            setContract(_contract);
        } catch (e) {
            console.log(e);
        }
    }, [abi, address, provider, setContract]);

    useEffect(() => getContract(), [getContract]);

    return contract;
};

export const useContractWithProviderAndFunctions = (address, abi, provider) => {
    const _contract = useContractWithProvider(address, abi, provider);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        if (_contract) {
            _contract.write = async ({ method, account, value, params }) => {
                try {
                    const gasPrice = await provider.eth.getGasPrice();
                    const args = {
                        from: account,
                        gasPrice: gasPrice,
                        value
                    };
                    await _contract.methods[method](...params).estimateGas(args);
                    const result = _contract.methods[method](...params).send(args);
                    return result;
                } catch (error) {
                    console.log(error);
                }
            }

            _contract.read = async ({ method, params }) => {
                try {
                    // console.log("Reading:::", method, ...params)
                    const result = _contract.methods[method](...params).call();
                    return result;
                } catch (e) {
                    console.log(e)
                }
            }
        }

        setContract(_contract);

    }, [_contract, setContract, provider]);

    return contract;
};

export const useContract = (address, abi, networkId = 56) => {
    const [contract, setContract] = useState(null);

    const contractFn = useCallback(async () => {
        setContract(await getContract(address, address, abi, networkId));
    }, [address, abi, networkId]);

    useEffect(() => contractFn(), [contractFn]);
    return contract;
};

export const getTokenInfo = async (address, chainId = 52) => {
    try {
        const contract = await getContract(`${chainId}:${address}`, address, HedgeCoin.abi, chainId)
        let decimals = await contract.methods.decimals().call();
        let symbol = await contract.methods.symbol().call();
        let name = await contract.methods.symbol().call().catch(() => 'Unknown');

        return {
            chainId, address, decimals: +decimals, name, symbol, image: '/unkown.svg', unverified: true
        }
    } catch (error) {
        console.log("Could not get token info, check provider, contract::", address, chainId);
        // console.error(error);
        return;
    }
};

export const useTokenContractWithProvider = (address, provider = window.web3) => {
    const contract = useContractWithProviderAndFunctions(address, HedgeCoin.abi, provider);
    const [supply, setSupply] = useState(0);
    const [decimals, setDecimals] = useState(0);
    const [symbol, setSymbol] = useState();
    const [name, setName] = useState();

    const balanceOf = useCallback(async (account) => {
        if (!contract || !account) {
            return 0;
        }
        let balance = await contract.methods.balanceOf(account).call();
        let decimals = await contract.methods.decimals().call();

        return Number(formatUnits(balance, decimals)).toFixed();
    }, [contract]);

    useEffect(() => {
        if (!contract || !address) {
            return;
        }
        init();
    }, [contract]);


    const init = async () => {
        try {
            let decimals = await contract.methods.decimals().call();
            let supply = await contract.methods.totalSupply().call();
            let name = await contract.methods.name().call().catch(() => "Unknown");
            let symbol = await contract.methods.symbol().call();
            supply = +(supply / 10 ** decimals).toFixed();
            setSupply(supply);
            setDecimals(decimals);
            setName(name);
            setSymbol(symbol);
        } catch (error) {
            console.log("Could not init contract, check provider, contract::", address, provider && parseInt(provider._provider.chainId));
            console.error(error);
        }

    };

    return { contract, balanceOf, supply, decimals, symbol, isValidToken: true, name, addressToken: address };
}

export const useTokenContract = (address, chainId = 56) => {
    // console.log("chain ID ========== ", chainId)
    const provider = getProvider(chainId);
    return useTokenContractWithProvider(address, provider);
};
