import Pairs from "hpay/content/IdoExchangePairs.json";

import axios from "axios";
import { useEffect, useState } from 'react';
import { checkLiquidity, fetchPair, getDefaultRouter } from '../utils/utils';
import { getTokenInfo } from './contracts/contracts';
import { networks, subscribeToNeworkChange } from './web3';

export const useIdoExchangePairs = (chainId) => {
    const [coins, setCoins] = useState([]);
    useEffect(() => {
        if (!chainId) {
            return;
        }
        setCoins(Pairs[chainId]);
    }, [setCoins, chainId]);

    return coins;
};

export const useTokenExchangePairs = (chainId, searchParams = '', excluded = []) => {
    const [coins, setCoins] = useState([]);

    useEffect(() => {
        fetchPairs(chainId, searchParams, excluded).then(setCoins);
    }, [setCoins, chainId, searchParams, excluded]);

    return coins;
};

export const useActiveChainNativeCoin = () => {
    const [coin, setCoin] = useState();
    useEffect(() => {
        subscribeToNeworkChange('tokenUtils', (data) => {
            if (!networks[data]) {
                return;
            }
            setCoin(networks[data].nativeToken);
        });
    }, [setCoin]);
    return coin;
}

const _fetchPairs = async () => {
    const { data: tokens } = await axios.get(`/exchange/tokens.json`);
    const { data: proxyedTokens } = await axios.get(`/exchange/proxyed-tokens.json`);
    const userTokens = JSON.parse(localStorage.getItem('userTokens')) || [];
    return { tokens, proxyedTokens, userTokens }
}

export const fetchUserToken = async (address) => {
    const tokenList = JSON.parse(localStorage.getItem('userTokens')) || [];

    const tokens = await Promise.all(Object.values(networks)
        .filter(item => !item.testnet)
        .map(async ({ chainId }) => {

            const token = await getTokenInfo(address, chainId);
            if (token) {
                const index = tokenList.findIndex(item => item.address.toLowerCase() === token.address.toLowerCase() && +item.chainId === +token.chainId);
                if (index === -1) {
                    const nativeCoin = getNativeCoin(chainId);
                    const defaultSwapper = getDefaultRouter(chainId)
                    if (!defaultSwapper || !defaultSwapper.enabled || !nativeCoin) {
                        return;
                    }

                    const pair = await fetchPair(token, nativeCoin, defaultSwapper);
                    if (pair && await checkLiquidity(pair)) {
                        tokenList.push(token);
                        localStorage.setItem('userTokens', JSON.stringify(tokenList));
                        return token;
                    }
                }
            }
        })).then(data => data.filter(Boolean));
    return tokens;
}


export const removeUserToken = (token) => {
    const tokenList = JSON.parse(localStorage.getItem('userTokens')) || [];
    const idx = tokenList.findIndex(item => item.address === token.address && +item.chainId === +token.chainId);
    if(idx !== -1){
        tokenList.splice(idx, 1);
    }
    localStorage.setItem('userTokens', JSON.stringify(tokenList));
}


export const fetchPairs = async (chainId, searchParams = '', excluded = []) => {
    let { tokens, proxyedTokens, userTokens } = await _fetchPairs();

    let result = [];
    if (tokens.length > 0) {
        result = tokens.map(item => ({ ...item, core: true }));;
    }

    if (proxyedTokens.length > 0) {
        proxyedTokens = proxyedTokens.map(item => ({ ...item, proxy: true }));
        result = [...proxyedTokens, ...result];
    }

    if (userTokens && userTokens.length > 0) {
        userTokens = userTokens.map(item => ({ ...item, image: '/unkown.svg', unverified: true }));
        result = [...userTokens, ...result];
    }


    if (!!chainId) {
        result = result.filter(item => +item.chainId === +chainId);
    }

    result = result
        .filter(item => !excluded.includes(item.address))
        .filter(tokensFilter(searchParams));
    return result;
}

export const getExchangeToken = async (chainId, symbol) => {
    const token = await fetchPairs(chainId, symbol);

    if (!token) {
        console.warn(`Tokens ${symbol} on chain ${chainId} was not found`);
    }

    return token[0];
}

const tokensFilter = (searchValue) => (token) => {
    if (token.disabled) {
        return false;
    }
    if (!searchValue || searchValue.length === 0) {
        return true;
    }
    return [token.name, token.symbol, token.chainId, token.address].join(' ').toLowerCase().includes(searchValue.toLowerCase());
}

export const getNativeCoin = (chainId) => {
    return networks[chainId] && networks[chainId].nativeToken
}

export const getUsdCoin = (chainId) => {
    return networks[chainId] && networks[chainId].usdToken
}

export const useUsdCoin = (chainId) => {
    const [coin, setCoin] = useState();
    useEffect(() => {
        setCoin(getUsdCoin(chainId));
    }, [setCoin, chainId]);
    return coin;
}

export const useNativeCoin = (chainId) => {
    const [coin, setCoin] = useState();
    useEffect(() => {
        setCoin(getNativeCoin(chainId));
    }, [setCoin, chainId]);
    return coin;
}

export const getIdoExchangePairToken = (symbol, chainId) => {
    const pairs = Pairs[chainId];
    if (pairs) {
        return pairs.find(pair => pair.symbol === symbol);
    }
}

export const getExplorerLink = (tokenAddress, chainId) => {
    return networks[chainId].explorer+ "token/" + tokenAddress
}

export const getExplorerTxLink = (txid, chainId) => {
    return networks[chainId].explorer+ "tx/" + txid
}

