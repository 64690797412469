import BigNumber from 'bignumber.js';
import VaultsData from "hpay/content/Vaults.json";
import FlashStaking from "hpay/contracts/FlashStaking.json";
import { useCallback } from "react";

import { useRefreshCurrentVault, useRefreshUserAmount } from "../state/flash-staking";
import { ContractFactoryHof } from "./contracts/contract.factory";
import { useContractWithProvider } from "./contracts/contracts";
import { createVaultWeb3, getBlockTime } from "./web3";

export const useVaultContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, FlashStaking.abi, provider);
    return contract;
};

export const useVault = () => {
    const fetchData = useCallback(async (address) => {
        const vaultContract = await ContractFactoryHof(createVaultWeb3(address)).create(FlashStaking.abi, address);

        if (!vaultContract) {
            return;
        }

        const vaultInfo = Object.values(VaultsData).find(item => item.address === address);
        const tokenDecimals = (10 ** (vaultInfo.details.tokenDecimals));


        const blockTime = await getBlockTime();
        const [, unStakeTax, stakeTax, earlyUnlockPenatly,
            maxStakingAmountPerRound, totalLockedValue, maxStakePerWalletPerRound, roundTime,
            stakingWindow, startTime, rounds] = await vaultContract.methods.getInfo().call();


        let round = +(await vaultContract.methods.getCurrentRound().call());
        const stakeToken = await vaultContract.methods.stakeToken().call();
        const rewardToken = await vaultContract.methods.rewardToken().call();
        const totalRewards = await vaultContract.methods.totalRewards().call();

        const _roundTime = roundTime * 1000;
        const _startTime = startTime * 1000;
        const _stakeWindow = stakingWindow * 1000;
        const vault = {
            address,
            maxLock: maxStakingAmountPerRound / tokenDecimals,
            return: 1000,
            stakeWindow: stakingWindow / 60 / 60,
            roundTime: roundTime / 60 / 60 / 24,
            totalRewardFund: totalRewards / tokenDecimals,
            totalLocked: totalLockedValue / tokenDecimals,
            maxLockPerWallet: Number(new BigNumber(maxStakePerWalletPerRound).div(tokenDecimals).toFixed()),
            penalty: earlyUnlockPenatly / 100,
            round: Math.min(+round + 1, rounds),
            stakeToken,
            rewardToken,
            totalRounds: +rounds,
            stakeTax: +stakeTax / 100,
            unStakeTax: +unStakeTax / 100,
            status: 0,
            blockTime,
            time: _startTime
        };



        if (blockTime > _startTime) {
            vault.status = 2; // Vault is live 
            vault.time = _startTime + ((round + 1) * _roundTime);

            if ((blockTime > (_startTime + (round * _roundTime))) && (blockTime < (_startTime + (round * _roundTime + _stakeWindow)))) {
                vault.status = 1; // Vault is live and staking is open
                vault.time = _startTime + (+round * _roundTime + _stakeWindow);
            }


            if (blockTime > (_startTime + (+rounds * _roundTime))) {
                vault.status = 3; // Vault ended 
                vault.time = Date.now();
            }
        }

        return vault;
    }, []);

    return fetchData;
};

export const useUserAmounts = (vault) => {
    const vaultContract = useVaultContract(vault);

    const fetchCapital = useCallback(async (account) => {

        if (!vaultContract || !account) {
            return 0;
        }

        const vaultInfo = Object.values(VaultsData).find(item => item.address === vault);
        const tokenDecimals = (10 ** vaultInfo.details.tokenDecimals);

        const result = await vaultContract.methods.userAmount(account).call();
        const [locked, unlocked, earned, pending, totalEarnings] = result;
        return [locked / tokenDecimals, unlocked / tokenDecimals, earned / tokenDecimals, pending / tokenDecimals, totalEarnings / tokenDecimals];
    }, [vaultContract, vault]);

    return fetchCapital;
};

export const useVaultActions = (account, vault) => {
    const vaultContract = useVaultContract(vault, window.web3);
    const [, refreshCurrentVault] = useRefreshCurrentVault(vault);
    const [, refreshUserStaking] = useRefreshUserAmount(vault);

    const lock = async (amount) => {
        const gasPrice = await window.web3.eth.getGasPrice();

        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0,
        };

        const decimals = Object.values(VaultsData).find(item => item.address === vault).details.tokenDecimals;
        amount = new BigNumber(amount).multipliedBy(10 ** decimals).toFixed();

        await vaultContract.methods.stake(amount).estimateGas(args);
        const result = vaultContract.methods.stake(amount).send(args);
        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });
    };

    const claim = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.claim().estimateGas(args);
        const result = vaultContract.methods.claim().send(args);

        return result.then(async result => {
            refreshCurrentVault();
            refreshUserStaking(account);
            return result;
        });;
    };

    const unlock = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.unstake().estimateGas(args);
        const result = vaultContract.methods.unstake().send(args);
        return result.then(async result => {
            refreshCurrentVault();
            refreshUserStaking(account);
            return result;
        });;
    };
    return { lock, claim, unlock };
}; 