import Ido from "hpay/contracts/Ido.json";
import IdoFactory from "hpay/contracts/IdoFactory.json";
import ERC20IdoFactory from "hpay/contracts/ERC20IdoFactory.json";
import FLFactory from "hpay/contracts/FLFactory.json";
import ERC20FLFactory from "hpay/contracts/ERC20FLFactory.json";

import { useCallback, useEffect, useMemo, useState } from "react";
import idoHttp, { getIdo } from '../api/ido';
import { useRefreshIdo, useRefreshInvestmentAmount, useRefreshUserBalanceAmount } from "../state/idos";
import { ZERO_ADDRESS } from '../utils/utils';
import { ContractFactoryHof } from "./contracts/contract.factory";
import { useContractWithProvider } from "./contracts/contracts";
import { getNativeCoin, useNativeCoin } from "./token-utils";
import { getBlockTime, getProvider } from "./web3";
import axios from "axios";

export const useIdoContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, Ido.abi, provider);
    return contract;
};

export const getIdoStatus = (ido, blockTime) => {
    let status = ido.status;
    if (new Date(ido.startTime).getTime() > blockTime) {
        status = 0; //started
    }

    if (new Date(ido.startTime).getTime() <= blockTime && new Date(ido.endTime).getTime() >= blockTime) {
        status = 1; // running
    }
    return status;
}

export const useIdo = () => {
    const fetchIdo = useCallback(async (address) => {
        const idoInfo = await getIdo(address)
        const _WEB3 = getProvider(+idoInfo.chainId);
        const idoContract = await ContractFactoryHof(_WEB3).create(Ido.abi, address);
        if (!idoContract) {
            return;
        }

        const blockTime = await getBlockTime(_WEB3);
        const [rate, minBuy, maxBuy, hardCap, softCap, capitalRaised, startTime, endTime] = await idoContract.methods.stats().call();
        const tokenDecimals = 10 ** (idoInfo.tokenDecimals);

        let requiredFunds = 0;
        if (idoContract.methods.requiredFunds) {
            requiredFunds = await idoContract.methods.requiredFunds().call().catch(() => true);
        }

        let whiteListManager;
        if (idoContract.methods.whitelistManagerAddress) {
            whiteListManager = await idoContract.methods.whitelistManagerAddress().call().catch(() => false);
        }

        let finalized = true
        if (idoContract.methods.finalized) {
            finalized = await idoContract.methods.finalized().call().catch(() => true);
        }

        let cancelled = false
        if (idoContract.methods.status) {
            const _status = await idoContract.methods.status().call().catch(() => 0);
            cancelled = _status < 2;
        }

        const tokenAddress = await idoContract.methods.token().call().catch(() => null)

        const ido = {
            minPurchase: minBuy / 1e18,
            maxPurchase: maxBuy / 1e18,
            capital: capitalRaised / 1e18,
            hardCap: hardCap / 1e18,
            rate: rate / tokenDecimals,
            softCap: softCap / 1e18,
            startTime: startTime * 1000,
            endTime: endTime * 1000,
            idoContract: address,
            token: tokenAddress,
            whitelisted: !!whiteListManager,
            whiteListManager,
            finalized,
            cancelled,
            requiredFunds: requiredFunds / tokenDecimals
        };

        if (ido.startTime > blockTime) {
            ido.status = 0; //started
        }

        if (ido.startTime <= blockTime && ido.endTime >= blockTime) {
            ido.status = 1; // running
            if (ido.capital >= ido.hardCap) {
                ido.status = 2; //ended
                ido.endTime = blockTime;
            }
        }

        if (ido.endTime < blockTime) {
            if (ido.capital >= ido.softCap) {
                ido.status = 2; // ended
            }

            if (ido.capital < ido.softCap) {
                ido.status = 3; //failed
            }
        }

        ido.canClaim = ido.status === 2 && finalized;

        return ido;
    }, []);
    return fetchIdo;
};

export const useIsAdmin = (idoContractAddress) => {
    const idoContract = useIdoContract(idoContractAddress);

    const fetchCapital = useCallback(async (account) => {

        if (!idoContract) {
            return false;
        }
        const hash = window.web3.utils.keccak256('OWNER_ROLE');
        const status = await idoContract.methods.hasRole(hash, account).call();

        return status;

    }, [idoContract]);

    return fetchCapital;
};

export const useIsWhiteListed = (idoAddress) => {
    const idoContract = useIdoContract(idoAddress);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return true;
        }
        const status = await idoContract.methods.isWhitelisted(account).call().catch(() => true);
        return status;
    }, [idoContract]);

    return fetchCapital;
};

export const useInvestedAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }
        const amount = await idoContract.methods.investments(account).call();
        return amount / 1e18;

    }, [idoContract]);

    return fetchCapital;
};

export const useBalanceAmount = (address) => {
    const idoContract = useIdoContract(address);

    const fetchCapital = useCallback(async (account) => {
        if (!idoContract) {
            return 0;
        }

        const newIdoData = await idoHttp.get(`/investment/${address}`, { headers: { 'accept': 'application/json', 'Content-Type': 'application/json' } });
        const tokenDecimals = 10 ** (newIdoData.ido.tokenDecimals || 18);

        const amount = await idoContract.methods.balance(account).call();
        return amount / tokenDecimals;

    }, [idoContract, address]);

    return fetchCapital;
};

export const useIdoAdminActions = (contract, account) => {
    const idoContract = useIdoContract(contract, window.web3);
    const [, refreshIdo] = useRefreshIdo();

    const resultHandler = useCallback(async result => {
        refreshIdo(contract);
        return result;
    }, [refreshIdo, contract]);

    return useOwnerHandles(account, idoContract, resultHandler);
};


export const useIdoActions = (address, account) => {
    const [, refreshIdo] = useRefreshIdo();
    const [, refreshInvestmentAmount] = useRefreshInvestmentAmount(address);
    const [, refreshBalance] = useRefreshUserBalanceAmount(address);
    const idoContract = useIdoContract(address, window.web3);

    const resultHandler = useCallback(async result => {
        refreshIdo(address);
        refreshBalance(account);
        refreshInvestmentAmount(account);
        return result;
    }, [refreshIdo, refreshBalance, refreshInvestmentAmount, account, address]);

    return useIdoHandles(account, idoContract, resultHandler);
};

export const useIdoHandles = (account, idoContract, handler) => {
    const [idoInfo, setIdoInfo] = useState();

    useEffect(() => {
        if (idoContract) {
            getIdo(idoContract._address).then(setIdoInfo);
        }
    }, [idoContract])

    return useMemo(() => {
        let swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw;

        if (!idoContract || !idoInfo) {
            return { swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw };
        }

        claim = async () => {
            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: 0
            };

            await idoContract.methods.claim().estimateGas(args);

            const result = idoContract.methods.claim().send(args);
            return result.then(handler);
        };

        refund = async () => {
            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: 0
            };
            await idoContract.methods.refund().estimateGas(args);
            const result = idoContract.methods.refund().send(args);

            return result.then(handler);
        };

        emergencyWithdraw = async () => {
            const gasPrice = await window.web3.eth.getGasPrice();
            const args = {
                from: account,
                gasPrice: gasPrice,
                value: 0
            };
            await idoContract.methods.emergencyWithdrawl().estimateGas(args);
            const result = idoContract.methods.emergencyWithdrawl().send(args);

            return result.then(handler);
        };

        const nativeCoin = getNativeCoin(idoInfo?.chainId);
        if (idoInfo?.baseSymbol === nativeCoin?.symbol) {
            swapAndBuy = async (tokenAddress, amount, minAmount) => {
                const _contract = await getNativeSwapAbi(idoContract._address);
                const gasPrice = await window.web3.eth.getGasPrice();
                const args = {
                    from: account,
                    gasPrice: gasPrice,
                    value: 0
                };

                await _contract.methods.swapToBaseAndBuy(
                    tokenAddress,
                    window.web3.utils.toWei(Number(amount).toFixed(12)),
                    window.web3.utils.toWei(Number(minAmount).toFixed(12))
                ).estimateGas(args);

                const result = _contract.methods.swapToBaseAndBuy(
                    tokenAddress,
                    window.web3.utils.toWei(Number(amount).toFixed(12)),
                    window.web3.utils.toWei(Number(minAmount).toFixed(12))
                ).send(args);

                return result.then(handler);
            }

            buyWithBase = async (amount) => {
                const _contract = await getNativeSwapAbi(idoContract._address);
                const gasPrice = await window.web3.eth.getGasPrice();
                const args = {
                    from: account,
                    gasPrice: gasPrice,
                    value: window.web3.utils.toWei(Number(amount).toFixed(12))
                };

                await _contract.methods.buyWithBase().estimateGas(args);
                const result = idoContract.methods.buyWithBase().send(args);

                return result.then(handler);
            }

        } else {
            swapAndBuy = async (token, amount, minAmount, baseIsNative) => {
                const _contract = await getERCSwapAbi(idoContract._address)

                const gasPrice = await window.web3.eth.getGasPrice();
                const args = {
                    from: account,
                    gasPrice: gasPrice,
                    value: baseIsNative ? window.web3.utils.toWei((+amount).toFixed(12)) : 0
                };
                if (baseIsNative) {
                    token = ZERO_ADDRESS;
                }

                await _contract.methods.swapToBaseAndBuy(
                    token,
                    window.web3.utils.toWei(Number(amount).toFixed(12)),
                    window.web3.utils.toWei(Number(minAmount).toFixed(12))
                ).estimateGas(args);

                const result = _contract.methods.swapToBaseAndBuy(
                    token,
                    window.web3.utils.toWei(Number(amount).toFixed(12)),
                    window.web3.utils.toWei(Number(minAmount).toFixed(12))
                ).send(args);

                return result.then(handler);
            };

            buyWithBase = async (amount) => {
                const _contract = await getERCSwapAbi(idoContract._address)

                const gasPrice = await window.web3.eth.getGasPrice();
                const args = {
                    from: account,
                    gasPrice: gasPrice,
                };

                await _contract.methods.buyWithBase(window.web3.utils.toWei(Number(amount).toFixed(12))).estimateGas(args);
                const result = _contract.methods.buyWithBase(window.web3.utils.toWei(Number(amount).toFixed(12))).send(args);
                return result.then(handler);
            };


        }

        return { swapAndBuy, buyWithBase, claim, refund, emergencyWithdraw };
    }, [idoInfo, account, idoContract, handler])
}

export const useOwnerHandles = (account, idoContract, handler) => {
    let fundPresale, finalizePresale;
    if (!idoContract) {
        return { fundPresale, finalizePresale };
    }

    fundPresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.fund().estimateGas(args);
        const result = idoContract.methods.fund().send(args);
        return result.then(handler);
    };


    finalizePresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.finalizePresale().estimateGas(args);
        const result = idoContract.methods.finalizePresale().send(args);
        return result.then(handler);

    };

    return { fundPresale, finalizePresale };

}


const getERCSwapAbi = async (address) => {
    const _contract = new window.web3.eth.Contract([{
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "buyWithBase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "minAmount",
                "type": "uint256"
            }
        ],
        "name": "swapToBaseAndBuy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }], address);

    return _contract;
}

const getNativeSwapAbi = async (address) => {
    return new window.web3.eth.Contract([{
        "inputs": [],
        "name": "buyWithBase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "token",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "minAmount",
                "type": "uint256"
            }
        ],
        "name": "swapToBaseAndBuy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }], address);
}


export const fetchIdos = () => {
    return axios.get("/idos/networks.json").then(response => {
        return response.data.filter(item => item.enabled);
    });
};

export const useIdoNetworks = () => {
    const [networks, setNetworks] = useState([]);
    useEffect(() => {
        fetchIdos().then(setNetworks);
    }, [setNetworks, fetchIdos]);

    return networks;
};

const networkTypeMapping = {
    "presale": {
        "native": {
            address: "factoryAddress",
            abi: IdoFactory.abi
        },
        "erc20": {
            address: "factoryAddressERC20",
            abi: ERC20IdoFactory.abi
        }
    },
    "fairlaunch": {
        "native": {
            address: "factoryAddressFairlaunch",
            abi: FLFactory.abi
        },
        "erc20": {
            address: "factoryAddressFairlaunchERC20",
            abi: ERC20FLFactory.abi
        }
    }
}

export const useIdoNetworkFactoryAddress = (networkId, baseAsset, type) => {
    const networks = useIdoNetworks();
    const nativeCoin = useNativeCoin(networkId);
    const [network, setNetwork] = useState([]);

    useEffect(() => {
        if (networks && nativeCoin) {
            const result = networks.find(item => item.chainId === networkId)
            if (result) {
                const quote = baseAsset === nativeCoin.symbol ? "native" : "erc20";
                const mapping = networkTypeMapping[type][quote];

                const _result = result[mapping.address];
                setNetwork([_result, mapping.abi]);
            }
        }
    }, [networks, setNetwork, nativeCoin, baseAsset, networkId, type]);

    return network;
};
