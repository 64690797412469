export function ContractFactoryHof(web3) {
    return ({
        create:  (abi, address) => {
            try {
                return new web3.eth.Contract(abi, address)
            } catch (error) {
                console.log("Could not create Contract")
                console.log(error);
            }
        }
    });
}