import * as Web3 from 'web3/dist/web3.min.js';

export const ProviderUnavailableError = {
    name: 'PROVIDER_UNAVAILABLE'
};

export const injectedProviderInit = async () => {
    if (typeof window === 'undefined') {
        return;
    }

    if (!isInjectedProviderAvailable()) {
        throw ProviderUnavailableError;
    }

    window.ethereum = window.injectedProvider.ethereum;
    window.web3 = window.injectedProvider.web3;

    if (window.ethereum) {
        await window.ethereum.request({method: 'eth_requestAccounts'});
        window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        console.log('No wallet provider available');
    }

    if (window.ethereum) {
        window.ethereum.on('accountsChanged', function (account) {
            Object.values(window.accountListeners).forEach(listener => listener(account[0]));
        });

        window.ethereum.on('chainChanged', function (networkId) {
            Object.values(window.networkChangeListeners).forEach(listener => listener('' + parseInt(networkId)));
            setTimeout(() => {
                Object.values(window.connectListeners).forEach((callback) => {
                    callback(true);
                });
            }, 200)
        });

        window.ethereum.on('disconnect', () => {
            console.log("disconnect")
            Object.values(window.connectListeners).forEach((callback) => {
                callback(false);
            });
        });
    }

    // Handle wallet lock event that does not fire sometimes
    const interval = setInterval(async () => {
        try {
            const account = window.web3.eth.getAccounts().then(accounts => {
                return accounts[0];
            });
            if (!account) {
                if (window.web3.currentProvider.close) {
                    await window.web3.currentProvider.close();
                }
                Object.values(window.connectListeners).forEach((callback) => {
                    callback(false);
                });
                clearInterval(interval);
            } 
        } catch (error) {
            clearInterval(interval);
        }
    }, 5000);
};



export function isMetamaskAvailable() {
    return typeof window !== "undefined" && window.injectedProvider.web3 && window.injectedProvider.web3.currentProvider && window.injectedProvider.web3.currentProvider.isMetaMask;
};

export function isInjectedProviderAvailable() {
    return typeof window !== "undefined" && (window.injectedProvider.ethereum || (window.injectedProvider.web3 && window.injectedProvider.web3.currentProvider));
}