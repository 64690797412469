import WalletConnectProvider from "@walletconnect/web3-provider";
import * as Web3 from 'web3/dist/web3.min.js';
import { networks } from '../web3';

export const walletConnectProviderInit = async () => {
    try {
        const provider = new WalletConnectProvider({
            rpc: Object.entries(networks).reduce((prev, [chainId, config]) => {
                prev[chainId] = config.rpcUrls[0];
                return prev;
            }, {}),
            bridge: 'https://bridge.walletconnect.org/',
            qrcode: true,
            pollingInterval: 12000,
        });

        // Ugly hack to be fixed in future releases
        provider.onConnect(() => {
            // provider.wc.createSession = async () => { console.log('Create session disabled'); };
        });

        provider.on('disconnect', async () => {
            window.ethereum = window.injectedProvider.ethereum;
            if (provider) {
                try {
                    await provider.close();
                    window.localStorage.removeItem('walletconnect');
                    Object.values(window.connectListeners).forEach((callback) => {
                        callback(false);
                    });
                } catch {
                    // Do nothing, might throw error if connection was not opened
                }
            }

        });

        provider.on('accountsChanged', function (account) {
            Object.values(window.accountListeners).forEach(listener => listener(account[0]));
        });

        provider.on('chainChanged', function (networkId) {
            Object.values(window.networkChangeListeners).forEach(listener => listener('' + parseInt(networkId)));
        });



        await provider.enable();
        window.ethereum = provider;
        window.web3 = new Web3(provider);
    } catch (error) {
        if (window.injectedProvider.ethereum) {
            window.ethereum = window.injectedProvider.ethereum;
            window.web3 = new Web3(window.ethereum);
        }
    }

    return window.web3;
};
