import BigNumber from 'bignumber.js';
import VaultsData from "hpay/content/Vaults.json";
import AutoCompundRelockStaking from "hpay/contracts/AutoCompundRelockStaking.json";
import { useCallback } from "react";
import { useRefreshCurrentReward } from '../state/autocompound-relock';
import { useRefreshCurrentVault, useRefreshUserAmount } from "../state/vault-staking";
import { BLOCKS_PER_YEAR } from "../utils/utils";
import { ContractFactoryHof } from "./contracts/contract.factory";
import { useContractWithProvider } from "./contracts/contracts";
import { createVaultWeb3, getBlockTime } from "./web3";


export const useVaultContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, AutoCompundRelockStaking.abi, provider);
    return contract;
};

export const useVault = () => {
    const getApr = useCallback((totalStaked, rewardsPerBlock) => {
        const totalRewardPricePerYear = new BigNumber(rewardsPerBlock).times(BLOCKS_PER_YEAR);
        const totalStakingTokenInPool = new BigNumber(Math.max(10, totalStaked));
        const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);

        let result = apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();

        if (result !== null) {
            result = Math.min(result, 20000);
        }
        return result;
    }, []);

    const getApy = useCallback(async (apr) => {
        if (apr === 0) {
            return 0;
        }
        const daily = 365;

        let apy = (1 + (apr / 100) / daily) ** daily - 1;
        if (apy !== null) {
            apy = Math.min(apy * 100, 50000);
        }
        return apy;

    }, []);

    const fetchData = useCallback(async (address) => {
        const vaultContract = await ContractFactoryHof(createVaultWeb3(address)).create(AutoCompundRelockStaking.abi, address);

        if (!vaultContract || !address) {
            return;
        }

        const vaultInfo = Object.values(VaultsData).find(item => item.address === address);
        const tokenDecimals = 10 ** vaultInfo.details.tokenDecimals;

        const blockTime = await getBlockTime();

        const [rewardSupply, totalSupply, startTime, rewardRate, maxStakingPerWallet, minStakePerWallet] = await vaultContract.methods.getInfo().call();

        const [lockTime, earlyUnlockPenalty] = await vaultContract.methods.timelockConfigurations().call();
        const allowEarlyUnlock = await vaultContract.methods.allowEarlyUnlock().call();
        const relockBonus = await vaultContract.methods.relockBonus().call();

        const returns = getApr(totalSupply / tokenDecimals, rewardRate / tokenDecimals);
        const apy = await getApy(returns);
        const { stakeTax, unStakeTax, hpayFee } = await vaultContract.methods.taxConfiguration().call();
        const { stakingToken: stakeToken, rewardsToken } = await vaultContract.methods.configuration().call();
        const started = await vaultContract.methods.started().call();
        
        let openForAll;
        if (vaultInfo.whitelisted) {
            openForAll = await vaultContract.methods.openForAll().call();
        }

        const vault = {
            address,
            return: returns,
            totalRewardFund: rewardSupply / tokenDecimals,
            totalLocked: totalSupply / tokenDecimals,
            maxLockPerWallet: maxStakingPerWallet / tokenDecimals,
            minLockPerWallet: minStakePerWallet / tokenDecimals,
            penalty: earlyUnlockPenalty / 100,
            stakeToken,
            rewardToken: rewardsToken,
            hpayFee: hpayFee / 100,
            stakeTax: (+stakeTax + +hpayFee) / 100,
            unStakeTax: (+unStakeTax + +hpayFee) / 100,
            status: 0,
            allowEarlyUnlock,
            lockTime: lockTime / 60 / 60 / 24,
            openForAll,
            startTime: startTime * 1000,
            started,
            relockBonus: relockBonus / 100,
            apy
        };

        if (blockTime > vault.startTime) {
            vault.status = 1;
        }

        if (vault.totalRewardFund === 0) {
            vault.status = 2;
        }

        return vault;
    }, [getApr, getApy]);

    return fetchData;
};


export const useVaultActions = (account, vault) => {
    const vaultContract = useVaultContract(vault, window.web3);
    const [, refreshCurrentVault] = useRefreshCurrentVault();
    const [, refreshUserStaking] = useRefreshUserAmount(vault);


    const relock = async (amount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.relock().estimateGas(args);
        const result = vaultContract.methods.relock().send(args);
        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });;
    };


    return { relock };
};


export const useCompoundRewardActions = (vault, account) => {
    const vaultContract = useVaultContract(vault, window.web3);
    const [, refreshReward] = useRefreshCurrentReward(vault);
    const [, refreshUserStaking] = useRefreshUserAmount(vault);

    const runCompund = async (batchSize) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.runCompund(batchSize).estimateGas(args);
        const result = vaultContract.methods.runCompund(batchSize).send(args);
        return result.then(async () => {
            await refreshUserStaking(account);
            await refreshReward();
        });
    };

    return { runCompund };
};

export const useCurrentReward = (vault) => {
    const vaultContract = useVaultContract(vault);
    const fetchReward = useCallback(async (batchSize = 10) => {
        if (!vaultContract) {
            return 0;
        }
        const result = await vaultContract.methods.currentReward(batchSize).call();

        return result / 1e18;
    }, [vaultContract]);

    return fetchReward;
};