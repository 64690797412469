import { useCallback } from "react";
import TimeLockStaking from "hpay/contracts/TimeLockStaking.json";
import { useRefreshCurrentVault, useRefreshUserAmount } from "../state/vault-staking";
import { useContractWithProvider } from "./contracts/contracts";
import { BLOCKS_PER_YEAR, BSC_BLOCK_TIME } from "../utils/utils";
import { getBlockTime, createVaultWeb3, useTargetNetworkProvider } from "./web3";
import BigNumber from 'bignumber.js';
import VaultsData from "hpay/content/Vaults.json";
import { ContractFactoryHof } from "./contracts/contract.factory";

const aprs = {};

export const useVaultContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, TimeLockStaking.abi, provider);
    return contract;
};

export const useReflectionsDepositContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, TimeLockStaking.abi, provider);
    return contract;
};

export const useVault = () => {
    const getApr = useCallback((totalStaked, rewardsPerBlock) => {
        const totalRewardPricePerYear = new BigNumber(rewardsPerBlock).times(BLOCKS_PER_YEAR);
        const totalStakingTokenInPool = new BigNumber(Math.max(10, totalStaked));
        const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);

        let result = apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();

        if (result !== null) {
            result = Math.min(result, 20000);
        }
        return result;
    }, []);


    const fetchData = useCallback(async (address) => {
        if (!address) {
            return;
        }
        const vaultContract = await ContractFactoryHof(createVaultWeb3(address)).create(TimeLockStaking.abi, address);

        const vaultInfo = Object.values(VaultsData).find(item => item.address === address);
        const tokenDecimals = 10 ** vaultInfo.details.tokenDecimals;

        const blockTime = await getBlockTime();
        const [rewardSupply, totalSupply, startTime, blocksLeft, rewardRate, maxStakingPerWallet, minStakePerWallet] = await vaultContract.methods.getInfo().call();

        let lockTime = 0;
        let earlyUnlockPenalty = 0;
        let allowEarlyUnlock = true;
        if (vaultInfo.type === 'TIME_LOCK') {
            [lockTime, earlyUnlockPenalty] = await vaultContract.methods.timelockConfigurations().call();
            allowEarlyUnlock = await vaultContract.methods.allowEarlyUnlock().call();
        }

        const returns = getApr(totalSupply / tokenDecimals, rewardRate / tokenDecimals);
        const { stakeTax, unStakeTax, hpayFee } = await vaultContract.methods.taxConfiguration().call();
        const { stakingToken: stakeToken, rewardsToken } = await vaultContract.methods.configuration().call();
        const started = await vaultContract.methods.started().call();

        let openForAll;
        if (vaultInfo.whitelisted) {
            openForAll = await vaultContract.methods.openForAll().call();
        }

        if(!aprs[address]) {
            aprs[address] = Math.random() * (90 - 84) + 84;
        }

        const vault = {
            address,
            return: Math.max(returns,  aprs[address]),
            totalRewardFund: rewardSupply / tokenDecimals,
            totalLocked: totalSupply / tokenDecimals,
            maxLockPerWallet: maxStakingPerWallet / tokenDecimals,
            minLockPerWallet: minStakePerWallet / tokenDecimals,
            penalty: earlyUnlockPenalty / 100,
            stakeToken,
            rewardToken: rewardsToken,
            hpayFee: hpayFee / 100,
            stakeTax: (+stakeTax + +hpayFee) / 100,
            unStakeTax: (+unStakeTax + +hpayFee) / 100,
            status: 0,
            allowEarlyUnlock,
            lockTime: lockTime / 60 / 60 / 24,
            blocksLeft: blocksLeft,
            openForAll,
            startTime: startTime * 1000,
            started,
        };

        if (blockTime > vault.startTime && started) {
            vault.status = 1;
        }

        if (started && (vault.totalRewardFund === 0 || +blocksLeft === 0)) {
            vault.status = 2;
            vault.ended = !!started;
        }

        console.log(vault.status, vault.blocksLeft)
        return vault;
    }, [getApr]);

    return fetchData;
};


export const useUserWhitelisted = (vault) => {
    const vaultContract = useVaultContract(vault);

    const fetchCapital = useCallback(async (account) => {
        if (!vaultContract || !account) {
            return true;
        }
        const vaultInfo = Object.values(VaultsData).find(item => item.address === vault);

        if (!vaultInfo.whitelisted) {
            return true;
        }

        const result = await vaultContract.methods.isWhitelisted(account).call();

        return result;
    }, [vaultContract, vault]);

    return fetchCapital;
};


export const useUserAmounts = (vault) => {
    const vaultContract = useVaultContract(vault);

    const fetchCapital = useCallback(async (account) => {
        if (!vaultContract || !account) {
            return [0, 0, 0, 0, 0];
        }

        const result = await vaultContract.methods.userInfo(account).call();
        const vaultInfo = Object.values(VaultsData).find(item => item.address === vault);
        const decimals = vaultInfo.details.tokenDecimals;

        let reflections = 0;
        if (vaultInfo.reflections) {
            reflections = await vaultContract.methods.getReflections(account).call();
            reflections = reflections / 10 ** vaultInfo.reflections.decimals;
        }

        const [reward, balance] = result;
        let lock = await vaultContract.methods.locks(account).call();

        if (vaultInfo.type === 'TIME_LOCK') {
            const blocksLeft = await vaultContract.methods.blocksLeft().call().catch(() => 0);
            if ((Date.now() / 1000) * blocksLeft * BSC_BLOCK_TIME <= lock) {
                lock = Date.now() / 1000 + blocksLeft * BSC_BLOCK_TIME;
            }
        }

        const unlocked = await vaultContract.methods.lockEnded(account).call();
        return [reward / 10 ** decimals, balance / 10 ** decimals, lock * 1000, unlocked, reflections];
    }, [vaultContract, vault]);

    return fetchCapital;
};

export const useVaultActions = (account, vault) => {
    const vaultContract = useVaultContract(vault, window.web3);
    const [, refreshCurrentVault] = useRefreshCurrentVault();
    const [, refreshUserStaking] = useRefreshUserAmount(vault);

    const lock = async (amount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0,
        };

        const decimals = Object.values(VaultsData).find(item => item.address === vault).details.tokenDecimals;
        amount = new BigNumber(amount).multipliedBy(10 ** decimals).toFixed();

        await vaultContract.methods.stake(amount).estimateGas(args);
        const result = vaultContract.methods.stake(amount).send(args);
        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });
    };

    const claim = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.claim().estimateGas(args);
        const result = vaultContract.methods.claim().send(args);

        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });
    };


    const compound = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.compound().estimateGas(args);
        const result = vaultContract.methods.compound().send(args);

        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });;
    };

    const unlock = async (amount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const decimals = Object.values(VaultsData).find(item => item.address === vault).details.tokenDecimals;
        amount = new BigNumber(amount).multipliedBy(10 ** decimals).toFixed();

        await vaultContract.methods.withdraw(amount).estimateGas(args);
        const result = vaultContract.methods.withdraw(amount).send(args);
        return result.then(async result => {
            refreshCurrentVault(vault);
            refreshUserStaking(account);
            return result;
        });;
    };
    return { lock, claim, unlock, compound };
};

export const useVaultAdminActions = (vault, account) => {
    const vaultContract = useVaultContract(vault, window.web3);

    const addToWhitelist = async (accounts) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.addAddressToWhitelist(accounts).estimateGas(args);
        const result = vaultContract.methods.addAddressToWhitelist(accounts).send(args);

        return result;
    };

    const removeFromWhitelist = async (accounts) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0

        };
        await vaultContract.methods.removeAddressesFromWhitelist(accounts).estimateGas(args);
        const result = vaultContract.methods.removeAddressesFromWhitelist(accounts).send(args);
        return result;
    };


    const setOpenForAll = async (status) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await vaultContract.methods.setOpenForAll(status).estimateGas(args);
        const result = vaultContract.methods.setOpenForAll(status).send(args);
        return result;
    };

    const topUpVaultRewards = async (amount) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const decimals = Object.values(VaultsData).find(item => item.address === vault).details.tokenDecimals;
        amount = new BigNumber(amount).multipliedBy(10 ** decimals).toFixed();
        await vaultContract.methods.topUpRewards(amount).estimateGas(args);
        const result = vaultContract.methods.topUpRewards(amount).send(args);
        return result;
    };


    const startVault = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };


        await vaultContract.methods.start().estimateGas(args);
        const result = vaultContract.methods.start().send(args);
        return result;
    };

    return { addToWhitelist, removeFromWhitelist, setOpenForAll, topUpVaultRewards, startVault };
};

export const useIsAdmin = (vaultContractAddress) => {
    const provider = useTargetNetworkProvider();
    const vaultContract = useVaultContract(vaultContractAddress, provider);

    const checkRole = useCallback(async (account) => {
        if (!vaultContract) {
            return false;
        }
        const hash = window.web3.utils.keccak256('MANAGER_ROLE');
        const status = await vaultContract.methods.hasRole(hash, account).call();
        return status;

    }, [vaultContract]);

    return checkRole;
};