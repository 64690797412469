import axios from "axios";
import { BACKEND_NODE_URL } from "gatsby-env-variables";
import { serialize } from "object-to-formdata"
const _axios = axios.create({
    baseURL: BACKEND_NODE_URL,
})

_axios.interceptors.response.use(
    (response) => response.data,
    (error) => console.log("error", error) || Promise.reject(error));


export const createIdo = async (body, file) => {
    var formData = serialize(body);
    formData.append("image", file);
    return await _axios.post(`/investment`, formData);
}

export const updateInfo = async (id, body, signature) => {
    var formData = serialize(body);
    return await _axios.put(`/investment/${id}`, formData, {
        headers: {
            "authorization": signature
        }
    });
}


export const getIdo = async (address) => {
    return _axios.get(`/investment/${address}`).then(data => {
        if(data.ido.type === 'fairlaunch') {
            data.ido.details = data.ido.fairLaunchDetails;
        }
        return ({
            ...data.ido,
            ...data.ido.details,
            socials: data.ido.info
        })
    });
}

export default _axios;