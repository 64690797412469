import { createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { useCurrentReward } from '../web3/autocompound-relock';

export const autoCompoundRelockSlice = createSlice({
    name: 'autoCompoundRelock',
    initialState: {
        currentReward: 0,
    },
    reducers: {
        setCurrentReward: (state, action) => {
            state.currentReward = action.payload;
        }
    },
});

export const { setCurrentReward } = autoCompoundRelockSlice.actions;

export const useCurrentBountyReward = (vault) => {
    const [status, refresh] = useRefreshCurrentReward(vault);

    const selector = useSelector(state => ({
        currentReward: state.autoCompoundRelock.currentReward, status
    }));

    useEffect(() => {
        refresh();
    }, [refresh, vault]);

    return selector;
};

export const useRefreshCurrentReward = (vaultAddress) => {
    const dispatch = useDispatch();
    const fetchData = useCurrentReward(vaultAddress);

    return useAsyncFn(async () => {
        const reward = await fetchData(10);
        dispatch(setCurrentReward(reward));
    }, [dispatch, fetchData]);
};


export default autoCompoundRelockSlice.reducer;