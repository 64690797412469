import { ethers } from 'ethers';
import { useCallback } from 'react';
import { useIndexedDBStore } from "use-indexeddb";

// Database Configuration
export const idbConfig = {
  databaseName: "orders-db",
  version: 1,
  stores: [
    {
      name: "orders",
      id: { keyPath: "id", autoIncrement: true },
      indices: [
        { name: "txid", keyPath: "txid", options: { unique: true } },
        { name: "account", keyPath: "account" },
      ],
    },
    {
      name: "trades",
      id: { keyPath: "id", autoIncrement: true },
      indices: [
        { name: "txid", keyPath: "txid", options: { unique: true } },
        { name: "account", keyPath: "account" },
      ],
    }
  ],
};

export const useOrdersActions = () => {
  return useActions('orders');
}


export const useTradesActions = () => {
  return useActions('trades');
}

export const useActions = (repository) => {
  const { getManyByIndex, deleteByID, add, update } = useIndexedDBStore(repository);

  const get = useCallback(async (account) => {
    return getManyByIndex("account", ethers.utils.getAddress(account));
  }, []);

  const save = async (order) => add(order);
  const _update = async (order) => update(order);
  const remove = async (id) => deleteByID(id);

  // return useMemo(() => ({ getOrders, saveOrder, updateOrder }), [getOrders, saveOrder, updateOrder]);
  return { get, save, update :_update, remove };
}

