/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import "./src/styles/style.scss"

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider;