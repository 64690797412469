import { PRINT_ERRORS } from "gatsby-env-variables";

import { JsonRpcProvider } from "@ethersproject/providers";
import { FACTORY_ADDRESS_MAP, Fetcher, INIT_CODE_HASH_MAP, Token, TokenAmount, Trade } from "@pancakeswap/sdk";
import { useParams } from "@reach/router";
import BigNumber from "bignumber.js";
import { parseUnits } from 'ethers/lib/utils';
import Vaults from "hpay/content/Vaults.json";
import React, { useEffect, useState } from 'react';
import SwapperConfigurations from "../../static/exchange/swappers.json";
import LuqidityProviders from "../../static/idos/liquidity-providers.json";

import { fetchPrice } from '../web3/price-data';
import { getNativeCoin, getUsdCoin } from '../web3/token-utils';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const _UNAVAILABLE_PAIRS_ = {}

export const printError = (err) => {
    if ((window && window.printError === undefined && PRINT_ERRORS) || (window && window.printError)) {
        console.log(err);
    }
}

/**
 * @typedef {import('@pancakeswap/sdk')} SwapSdk
 * @typedef  {Object<string, Object<string, SwapSdk>} SwapperSDK
 */

/**
 * Sdk mappings
 * @type {SwapperSDK}
 */
export let SwapperSDKS = {};

for (const key of Object.keys(SwapperConfigurations)) {
    const chainId = SwapperConfigurations[key].chainId;
    const router = SwapperConfigurations[key].router;

    if (!FACTORY_ADDRESS_MAP[chainId]) {
        FACTORY_ADDRESS_MAP[chainId] = {};
    }

    FACTORY_ADDRESS_MAP[chainId][router] = SwapperConfigurations[key].swapFactory;

    const initCodeHash = SwapperConfigurations[key].initCodeHash;
    if (initCodeHash) {

        if (!INIT_CODE_HASH_MAP[chainId]) {
            INIT_CODE_HASH_MAP[chainId] = {};
        }

        INIT_CODE_HASH_MAP[chainId][router] = initCodeHash;
    }
}

export const UndefinedToZero = (prop) => (obj) => (obj && obj[prop]) || 0;

export const roundToEight = (num) => {
    return +(Math.floor(num + "e+8") + "e-8");
};

export const roundToSix = (num) => {
    return +(Math.floor(num + "e+6") + "e-6");
};
export const roundToFour = (num) => {
    return +(Math.floor(num + "e+4") + "e-4");
};
export const roundToTwo = (num) => {
    return +(Math.floor(num + "e+2") + "e-2");
};
export const round = (num) => {
    return +(Math.floor(num + "e+0") + "e-0");
};

export const BSC_BLOCK_TIME = 3;
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365);

export const usePersistedState = (key, defaultValue) => {
    const [state, setState] = React.useState(() => {
        const persistedState = localStorage.getItem(key);
        return persistedState ? JSON.parse(persistedState) : defaultValue;
    });

    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);
    return [state, setState];
};

export const checkLiquidity = async (pair) => {
    const usdCoin = getUsdCoin(pair.swapper.chainId);
    const referenceRouter = getDefaultRouter(pair.swapper.chainId);
    const nativeCoin = getNativeCoin(pair.swapper.chainId);

    let _liquididyAmount;
    if (pair.token0.address === nativeCoin.address) {
        _liquididyAmount = pair.reserve0.toFixed(nativeCoin.decimals);
    }

    if (pair.token1.address === nativeCoin.address) {
        _liquididyAmount = pair.reserve1.toFixed(nativeCoin.decimals);
    }

    let _liquididyPrice = await fetchPrice(usdCoin, nativeCoin, referenceRouter.router, referenceRouter.chainId);


    if (_liquididyPrice * _liquididyAmount < 5000) {
        // console.log(` 
        // ==== Insufficient liquidity value 
        //  ${_liquididyPrice * _liquididyAmount} < 5000 === 
        //  @
        //  ${pair.swapper.router} ChainId:: ${pair.swapper.chainId}
        // \n\n`);

        return false;
    }
    return true;
}

export const fetchPair = async (tokenA, tokenB, swapper) => {
    const provider = new JsonRpcProvider(swapper.rpcUrls[0]);
    const _tokenA = new Token(swapper.chainId, tokenA.address, tokenA.decimals, tokenA.symbol)
    const _tokenB = new Token(swapper.chainId, tokenB.address, tokenB.decimals, tokenB.symbol)

    if (_UNAVAILABLE_PAIRS_[`${tokenA.address}/${tokenB.address}/${swapper.router}`]) {
        window && window.printError && console.log(`Not available ${tokenA.symbol}/ ${tokenB.symbol} at ${swapper.router}@${tokenA.chainId}`);
        return;
    }

    try {
        const pair = await Fetcher.fetchPairData(_tokenA, _tokenB, provider, swapper.router);
        pair.swapper = swapper;
        return pair;
    } catch (error) {
        _UNAVAILABLE_PAIRS_[`${tokenA.address}/${tokenB.address}/${swapper.router}`] = true;
        console.log(`Could not build pair ${tokenA.symbol}/ ${tokenB.symbol} at ${swapper.router}@${tokenA.chainId}`);
        printError(error);
    }
}

export const computeTrade = async (amountIn, path, swapper, verifyLiquidity = true) => {
    const pairs = [];

    /**
     * @type {SwapSdk.Token[]} 
     */
    const tokens = path.map(item => new Token(
        item.chainId,
        item.address,
        item.decimals,
        item.symbol
    ));

    for (let index = 1; index < tokens.length; index++) {
        const tokenA = tokens[index - 1];
        const tokenB = tokens[index];

        const pair = await fetchPair(tokenA, tokenB, swapper);
        if (pair) {
            const valid = !verifyLiquidity || await checkLiquidity(pair);
            if (valid) {
                pairs.push(pair);
            }
        }
    }

    if (pairs.length == 0) {
        printError(`Could not build trading configuration  ${swapper.router}`);
        throw "Could not build trading configuration";
    }

    const _in = new Token(
        path[0].chainId,
        path[0].address,
        path[0].decimals
    );


    const trade = Trade.bestTradeExactIn(
        pairs,
        new TokenAmount(_in, parseUnits('' + Number(amountIn).toFixed(_in.decimals), +_in.decimals)),
        tokens.pop(),
        { maxHops: 2 }
    ).pop();

    // console.log("\n===== COMPUTING TRADE ======");
    // console.log(`${path.map(item => item.address).join(' and ')} @ ${swapper.router}`);
    // console.log(`Trade::${trade.outputAmount.toFixed(18)}`);

    return trade;
};

export const useIdoAddress = () => {
    const params = useParams();
    const [address, setAddress] = useState();

    useEffect(async () => {
        setAddress(params.idoAddress);
    }, [params, setAddress]);

    return address;
};

export const useVaultAddress = () => {
    const params = useParams();
    const [address, setAddress] = useState();

    useEffect(() => {
        setAddress(Vaults[params.vaultAddress].address);
    }, [params, setAddress]);

    return address;
};

export const getDefaultRouterAddress = (chainId) => {
    return getDefaultRouter(chainId)?.router;
}

export const getDefaultRouter = (chainId) => {
    return Object.values(SwapperConfigurations).find(item => +item.chainId === +chainId && item.default);
}

export const getLiquidityProviders = (chainId) => {
    return Object.values(LuqidityProviders).filter(item => +item.chainId === +chainId  && item.enabled);
}

export const useLiquidityProviders = (chainId) => {
    const [providers, setProviders] = useState()
    useEffect(() => {
        setProviders(getLiquidityProviders(chainId));
    }, [chainId])
    return providers;
}
