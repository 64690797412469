exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dex-js": () => import("./../../../src/pages/dex.js" /* webpackChunkName: "component---src-pages-dex-js" */),
  "component---src-pages-docs-about-js": () => import("./../../../src/pages/docs/about.js" /* webpackChunkName: "component---src-pages-docs-about-js" */),
  "component---src-pages-docs-branding-js": () => import("./../../../src/pages/docs/branding.js" /* webpackChunkName: "component---src-pages-docs-branding-js" */),
  "component---src-pages-docs-faq-general-js": () => import("./../../../src/pages/docs/faq/general.js" /* webpackChunkName: "component---src-pages-docs-faq-general-js" */),
  "component---src-pages-docs-faq-hedgetalk-js": () => import("./../../../src/pages/docs/faq/hedgetalk.js" /* webpackChunkName: "component---src-pages-docs-faq-hedgetalk-js" */),
  "component---src-pages-docs-faq-ido-js": () => import("./../../../src/pages/docs/faq/ido.js" /* webpackChunkName: "component---src-pages-docs-faq-ido-js" */),
  "component---src-pages-docs-faq-js": () => import("./../../../src/pages/docs/faq.js" /* webpackChunkName: "component---src-pages-docs-faq-js" */),
  "component---src-pages-docs-faq-leaderboard-js": () => import("./../../../src/pages/docs/faq/leaderboard.js" /* webpackChunkName: "component---src-pages-docs-faq-leaderboard-js" */),
  "component---src-pages-docs-faq-mm-js": () => import("./../../../src/pages/docs/faq/mm.js" /* webpackChunkName: "component---src-pages-docs-faq-mm-js" */),
  "component---src-pages-docs-faq-partnership-js": () => import("./../../../src/pages/docs/faq/partnership.js" /* webpackChunkName: "component---src-pages-docs-faq-partnership-js" */),
  "component---src-pages-docs-faq-products-js": () => import("./../../../src/pages/docs/faq/products.js" /* webpackChunkName: "component---src-pages-docs-faq-products-js" */),
  "component---src-pages-docs-faq-rewards-js": () => import("./../../../src/pages/docs/faq/rewards.js" /* webpackChunkName: "component---src-pages-docs-faq-rewards-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-manager-js": () => import("./../../../src/pages/docs/manager.js" /* webpackChunkName: "component---src-pages-docs-manager-js" */),
  "component---src-pages-docs-official-email-js": () => import("./../../../src/pages/docs/official-email.js" /* webpackChunkName: "component---src-pages-docs-official-email-js" */),
  "component---src-pages-embedded-audited-kyced-js": () => import("./../../../src/pages/embedded-audited-kyced.js" /* webpackChunkName: "component---src-pages-embedded-audited-kyced-js" */),
  "component---src-pages-embedded-certified-js": () => import("./../../../src/pages/embedded-certified.js" /* webpackChunkName: "component---src-pages-embedded-certified-js" */),
  "component---src-pages-hedgetalk-js": () => import("./../../../src/pages/hedgetalk.js" /* webpackChunkName: "component---src-pages-hedgetalk-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-hpad-js": () => import("./../../../src/pages/hpad.js" /* webpackChunkName: "component---src-pages-hpad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kycaudit-js": () => import("./../../../src/pages/kycaudit.js" /* webpackChunkName: "component---src-pages-kycaudit-js" */),
  "component---src-pages-old-rewards-js": () => import("./../../../src/pages/old-rewards.js" /* webpackChunkName: "component---src-pages-old-rewards-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-submit-request-js": () => import("./../../../src/pages/submit-request.js" /* webpackChunkName: "component---src-pages-submit-request-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-team-old-js": () => import("./../../../src/pages/team - old.js" /* webpackChunkName: "component---src-pages-team-old-js" */),
  "component---src-pages-term-ilo-js": () => import("./../../../src/pages/term-ilo.js" /* webpackChunkName: "component---src-pages-term-ilo-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transak-js": () => import("./../../../src/pages/transak.js" /* webpackChunkName: "component---src-pages-transak-js" */),
  "component---src-pages-transfer-js": () => import("./../../../src/pages/transfer.js" /* webpackChunkName: "component---src-pages-transfer-js" */),
  "component---src-pages-vaults-js": () => import("./../../../src/pages/vaults.js" /* webpackChunkName: "component---src-pages-vaults-js" */),
  "component---src-pages-wechat-js": () => import("./../../../src/pages/wechat.js" /* webpackChunkName: "component---src-pages-wechat-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */)
}

