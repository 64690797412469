import FairLaunch from "hpay/contracts/FairLaunch";
import { useCallback } from "react";
import { useRefreshIdo, useRefreshInvestmentAmount, useRefreshUserBalanceAmount } from "../state/idos";
import { useContractWithProvider } from "./contracts/contracts";
import HegeCoin from "hpay/contracts/HedgeCoin.json";

import { getIdo } from '../api/ido';
import { ContractFactoryHof } from './contracts/contract.factory';
import { useIdoHandles } from './idos';
import { getBlockTime, getProvider } from "./web3";
import { BigNumber } from 'ethers';

export const useIdoContract = (address, provider = window.web3) => {
    const contract = useContractWithProvider(address, FairLaunch.abi, provider);
    return contract;
};

export const useIdo = () => {
    const fetchIdo = useCallback(async (address) => {
        const idoInfo = await getIdo(address)
        const _WEB3 = getProvider(+idoInfo.chainId);
        const fairLaunchContract = ContractFactoryHof(_WEB3).create(FairLaunch.abi, address);

        if (!fairLaunchContract) {
            return;
        }

        const tokenDecimals = 10 ** (idoInfo.tokenDecimals || 18);

        const blockTime = await getBlockTime();
        const {
            amountForSale,
            tokenSupply,
            minBuy,
            maxBuy,
            softCap,
            startTime,
            endTime } = await fairLaunchContract.methods.launchConfiguration().call();

        const rate = await fairLaunchContract.methods.getScaledRate().call();
        const capitalRaised = await fairLaunchContract.methods.capitalRaised().call();

        let requiredFunds = 0;
        if (fairLaunchContract.methods.requiredFunds) {
            requiredFunds = await fairLaunchContract.methods.requiredFunds().call().catch(() => true);
        }

        let whiteListManager;
        if (fairLaunchContract.methods.whitelistManagerAddress) {
            whiteListManager = await fairLaunchContract.methods.whitelistManagerAddress().call().catch(() => false);
        }

        let finalized = true
        if (fairLaunchContract.methods.finalized) {
            finalized = await fairLaunchContract.methods.finalized().call().catch(() => true);
        }

        let cancelled = false
        if (fairLaunchContract.methods.status) {
            const _status = await fairLaunchContract.methods.status().call().catch(() => 0);
            cancelled = _status < 2;
        }

        const tokenAddress = await fairLaunchContract.methods.token().call().catch(() => null);

        const tokenContract = await ContractFactoryHof(_WEB3).create(
            HegeCoin.abi,
            tokenAddress
        );

        const supply = await tokenContract.methods.totalSupply().call();
        const isFairlaunch = +(supply / tokenDecimals).toFixed() === +(requiredFunds / tokenDecimals).toFixed()
        const ido = {
            tokenSupply: tokenSupply / tokenDecimals,
            amountForSale: amountForSale / tokenDecimals,
            minPurchase: minBuy / 1e18,
            maxPurchase: maxBuy / 1e18,
            capital: capitalRaised / 1e18,
            softCap: softCap / 1e18,
            requiredFunds: requiredFunds / tokenDecimals,
            rate: (rate / tokenDecimals) / 1e18,
            startTime: startTime * 1000,
            endTime: endTime * 1000,
            idoContract: address,
            isFairlaunch,
            capital: capitalRaised / 1e18,
            finalized,
            token: tokenAddress,
            whiteListManager,
            whitelisted: !!whiteListManager,
            cancelled,
            idoContract: address
        };

        if (ido.startTime > blockTime) {
            ido.status = 0; //started
        }

        if (ido.startTime <= blockTime && ido.endTime >= blockTime) {
            ido.status = 1; // running
            if (ido.capital >= ido.hardCap) {
                ido.status = 2; //ended
                ido.endTime = blockTime;
            }
        }

        if (ido.endTime < blockTime) {
            if (ido.capital >= ido.softCap) {
                ido.status = 2; // ended
            }

            if (ido.capital < ido.softCap) {
                ido.status = 3; //failed
            }
        }

        ido.canClaim = ido.status === 2 && finalized;

        return ido;
    }, []);

    return fetchIdo;
};

export const useGetPresaleRate = () => {
    const flPresaleRate = useCallback(async (address) => {
        const idoInfo = await getIdo(address)
        const _WEB3 = getProvider(+idoInfo.chainId);
        const fairLaunchContract = ContractFactoryHof(_WEB3).create(FairLaunch.abi, address);

        if (!fairLaunchContract) {
            return;
        }
        const tokenDecimals = 10 ** (idoInfo.tokenDecimals || 18);
        const fullPresaleRate = await fairLaunchContract.methods.getRate().call();
        const realPresaleRate =  (fullPresaleRate / tokenDecimals).toFixed(2);
        return realPresaleRate;
    }, []);
    return flPresaleRate;
}

export const useIdoAdminActions = (contract, account) => {
    const idoContract = useIdoContract(contract, window.web3);
    const [, refreshIdo] = useRefreshIdo();

    const fundPresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.fund().estimateGas(args);
        const result = idoContract.methods.fundFairLaunch().send(args);
        return result.then(data => {
            refreshIdo(account);
            return data;
        });
    };


    const finalizePresale = async () => {
        const gasPrice = await window.web3.eth.getGasPrice();
        const args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        await idoContract.methods.finalizePresale().estimateGas(args);
        const result = idoContract.methods.finalizePresale().send(args);
        return result.then(data => {
            refreshIdo(account);
            return data;
        });
    };

    return { fundPresale, finalizePresale };
};


export const useIdoActions = (address, account) => {
    const [, refreshIdo] = useRefreshIdo();
    const [, refreshInvestmentAmount] = useRefreshInvestmentAmount(address);
    const [, refreshBalance] = useRefreshUserBalanceAmount(address);
    const idoContract = useIdoContract(address, window.web3);

    const resultHandler = async result => {
        refreshIdo(address);
        refreshBalance(account);
        refreshInvestmentAmount(account);
        return result;
    };
    return useIdoHandles(account, idoContract, resultHandler);
}

